<template>
    <div>
        <h1>Office Codes</h1>
    </div>
</template>

<script>
    export default {
        name: 'OfficeCodes'
    };
</script>
